/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FilterQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService } from '@nest/common/frontend/core';
import { SalesChannelDto } from '@nest/domain/entities-metadata/dtos';
import { CreateSalesChannelDto, UpdateSalesChannelDto } from '@nest/domain/sale-orders/shared';

@Injectable({
	providedIn: 'root'
})
export class SalesChannelDataAccessService {
	private api = inject(ApiService);

	// GET @ sales-channels/
	getAll(query: EntityQueryDto<SalesChannelDto>) {
		return this.api.post<SalesChannelDto[]>(`SalesChannel.getAll`, { body: { data: query } });
	}

	// GET @ sales-channels/:id
	getById(id: number, query: FindOptionsDto<SalesChannelDto>) {
		return this.api.post<SalesChannelDto>(`SalesChannel.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	count(query: FilterQueryDto<SalesChannelDto>) {
		return this.api.post<number>(`SalesChannel.count`, { body: { data: query } });
	}

	// POST @ sales-channels/
	create(body: CreateSalesChannelDto) {
		return this.api.post<SalesChannelDto>(`sales-channels/`, { body });
	}

	// PUT @ sales-channels/:id
	update(id: number, body: UpdateSalesChannelDto) {
		return this.api.put<SalesChannelDto>(`sales-channels/${id}`, { body });
	}

	// DELETE @ sales-channels/:id
	delete(id: number) {
		return this.api.delete<void>(`sales-channels/${id}`);
	}

	// GET @ sales-channels/:id/preview-prices
	previewPrices(id: number) {
		return this.api.get<void>(`sales-channels/${id}/preview-prices`);
	}
}
