/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { ApiService } from '@nest/common/frontend/core';
import { EntityQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { SaleOrderDto, SaleOrderLineDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class SaleOrderLineDataAccessService {
	private api = inject(ApiService);

	// GET @ sale-orders/lines/
	getAll(query: EntityQueryDto<SaleOrderDto>) {
		return this.api.post<SaleOrderLineDto[]>(`saleOrderLines.getAll`, { body: { data: query } });
	}

	// GET @ sale-orders/lines/count
	count(query: any) {
		return this.api.post<number>(`saleOrderLines.count`, { body: { data: query } });
	}

	// GET @ sale-orders/lines/:id
	getById(id: number, query: FindOptionsDto<SaleOrderLineDto>) {
		return this.api.get<SaleOrderLineDto>(`saleOrderLines.getById`, { body: { data: { id, fields: query?.fields } } });
	}
}
