/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { ApiService } from '@nest/common/frontend/core';

@Injectable({
	providedIn: 'root'
})
export class SaleOrderReserveDataAccessService {
	private api = inject(ApiService);

	// POST @ orders/:saleOrderId/reserve
	reserve(saleOrderId: number) {
		return this.api.post<void>(`orders/${saleOrderId}/reserve`, {});
	}

	// POST @ orders/:saleOrderId/unreserve
	unreserve(saleOrderId: number) {
		return this.api.post<void>(`orders/${saleOrderId}/unreserve`, {});
	}
}
